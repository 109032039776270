Object.removeByValue = function(obj, value){
    if (Object.prototype.toString.call( obj ) === '[object Array]') {
        var index = obj.indexOf(value);
        if (index > -1) {
           obj.splice(index, 1);
           return obj;
        }
    } else {
        var i;
        for(i in obj){
            if(obj.hasOwnProperty(i)) {
                if(value === obj[i]){
                    delete obj[i];
                    return true;
                }
            }
        }   
    } 
    return obj;   
};

$( function() {
    /**
    * Mắc định chạy khi chạy web
    */
    // Checkbox, Radio, Toggle || Thêm element cần thiết 
    $(".c-checkbox").find('.c-checkbox__tick').remove();
    $(".c-checkbox").find('input').after('<span class="c-checkbox__tick"><span class="check"></span></span>');
    $(".c-checkbox").find('.c-radio__circle').remove();
    $(".c-radio").find('input').after('<span class="c-radio__circle"><span class="c-radio__check"></span></span>');
    $(".c-toggle").find('input').after('<span class="toggle"></span>');
    /**
    * Xử lý khi có sự kiện của bàn phím, chuột,...
    */
    // focus event
    $("body").on("focus", "input, textarea", function() {
        // select single choice
        if ($(this).closest(".c-select__list") && $(this).attr("type") == "radio") {
            $(this).closest(".c-select").removeClass("is-focused");
            $(this).closest(".form-group").removeClass("is-focused");
            $(this).closest(".datepicker").removeClass("is-focused");
            $(".overlay-transparent").remove();
        } else {
            $(this).closest(".form-group").addClass("is-focused");
        }
    });
    /**
    * blur event
    */
    $("body").on("blur", "input:not([type=checkbox]), textarea, [ht-trigger=datepicker]", function() {
        $(this).closest(".form-group").removeClass("is-focused");
        $(this).closest(".datepicker").removeClass("is-focused");
    });
    // Xử lý sự kiện trigger
    $("body").on("click", "[ht-trigger]", function() {
        // Phần chung cho tất cả trigger
        var $thisTrigger = $(this);
        var $trigger = $thisTrigger.attr("ht-trigger");
        var $submit = $thisTrigger.attr("ht-type");
        var $target = $thisTrigger.attr("ht-target");
        switch ($trigger) {
            case "c-select":
                if ($(this).parent().hasClass("is-focused")) {
                    $(this).parent().removeClass("is-focused");
                    $(".overlay-transparent").remove();
                } else {
                    $(".c-select").removeClass("is-focused");
                    $(".form-group").removeClass("is-focused");
                    $(".overlay-transparent").remove();
                    $(this).parent().addClass("is-focused");
                    $("body").append('<div class="overlay-transparent" ht-close="c-select"></div>');// Mục đích để đóng box khi click anywhere
                }
            break;
            case "c-modal":
                // Hiển thị background mờ
                $("body").append('<div class="modal-backdrop"></div>');
                $("body").addClass("modal-open");// không chô scroll
                $(".modal-backdrop").addClass("show");
                // Hiển thị box modal
                $target = $($thisTrigger.attr("ht-target")); //box muốn hiển thị.
                $target.css("display", "block");
                $targetClose = $thisTrigger.attr("ht-target-close");//box muốn đóng.
                if ($targetClose !== undefined) {
                    $($targetClose).removeClass("show");
                    $($targetClose).css("display", "none");
                    $("body").find(".modal-backdrop:last").remove();
                }
                // sau 200ms thì sẽ chạy
                setTimeout(function() {
                    $target.addClass("show");
                }, 200);
            break;
            case "datepicker":
                if ($(this).closest(".datepicker").hasClass("is-focused")) {
                    $(this).closest(".datepicker").removeClass("is-focused");
                } else {
                    $(this).closest(".datepicker").addClass("is-focused");
                    $("body").append('<div class="overlay-transparent" ht-close="datepicker"></div>');
                }
            break;
            case "animation":
                $thisTrigger.addClass("animated " + $target);
                setTimeout(function() {
                    $thisTrigger.removeClass('animated ' + $target )    ;// sau 3s trở về trạng thái ban đầu
                }, 3000);
            break;
        }
    });
    /**
    * Xử lý attribute ht-close
    */
    $("body").on("click", "[ht-close]", function(e) {
        // Phần chung cho tất cả trigger
        var $thisClose = $(this);
        var $close = $thisClose.attr("ht-close");
        var $reload = $thisClose.attr("ht-reload");
        switch ($close) {
            case "c-select":
                $(".c-select").removeClass("is-focused");
                $(".overlay-transparent").remove();
            break;
            case "c-modal":
                $target = $thisClose.attr("ht-target-close");
                if ($target) {
                    $($target).removeClass("show");
                    $("body").find(".modal-backdrop:last").removeClass("show");
                    setTimeout(function() {
                        $($target).css("display", "none");
                        $("body").find(".modal-backdrop:last").remove();
                    }, 200);
                } else {
                    $(".modal-backdrop").removeClass("show");
                    $(".modal").removeClass("show");
                    setTimeout(function() {
                        $(".modal").css("display", "none");
                        $("body").removeClass("modal-open");
                        $(".modal-backdrop").remove();
                    }, 200);
                }
            break;
            case "datepicker":
                $(".datepicker").removeClass("is-focused");
                $(".overlay-transparent").remove();
            break;
        }
        if ($reload === "true") {
            $redirect = ($thisClose.attr("ht-redirect") === 'false') ? "" : $thisClose.attr("ht-redirect");
            window.location.href = $redirect;
        }
    });
    /**
    * Xử lý attribute ht-skip || thực hiện sự kiện nhưng bỏ qua sự kiện parent
    */
    $("body").on("click", "[ht-skip]", function(e) {
        e.stopPropagation();
    });
    /**
     * Check input
     */
    $("input, textarea").change(function() {
        var $this = $(this);
        var $value = $this.val();
        if ($value !== "") {
            $this.closest(".form-group").removeClass("is-empty");
        } else {
            $this.closest(".form-group").addClass("is-empty");
        }
    });
});

/**
* Select
*/

$(document).ready(function() {
    //Select single || Xử lý select 1 giá trị duy nhât 
    $("body").on("click", ".c-select__list .c-radio", function() {
        var $this = $(this);
        var $value = $this.find("span").text();
        $this.closest(".c-select").find("div.form__input").text($value);
        $this.closest(".c-select").find("input.form__input").val($value);
        $this.closest(".c-select").find(".c-radio").removeClass("is-selected");
        $this.closest(".c-radio").addClass("is-selected");
        $this.closest(".c-select").removeClass('is-focused');
        $('.overlay-transparent').remove();
    });

    // Select multi || Lấy all giá trị được chọn và ghi vào input để hiển thị
    $("input[type=checkbox]").click(function() {
        var $this = $(this);
        var $value = "";
        var $htempty = $this.closest(".c-select").find("div.form__input").attr("ht-empty");
        $this.closest(".c-select").find("input:checked").each(function() { // Vòng lặp để lấy tất cả giá trị được chọn
            $value += $(this).parent().find("span").text() + ", ";
        });
        $value = $value.slice(0, -2); // Xóa bỏ 2 ký tự sau cùng
        if ($value !== "") {
            $this.closest(".c-select").find("div.form__input").text($value);
        } else {
            $this.closest(".c-select").find("div.form__input").text($htempty);
        }
    });
});

/**
* Xây dựng các hàm
*/

// Lấy input có attribute truyền vào
function getElementsByAttr(attribute, content)
{
    el = document.querySelectorAll("[" + attribute + "]");
    for (var i = 0; i < el.length; i++) {
        if (el[i].getAttribute(attribute) == content) {
            return el[i];
        }
    }
}

// convert int to string with format money
function formatNumber(num)
{
    var array = num.toString().split("");
    var index = -3;
    while (array.length + index > 0) {
        array.splice(index, 0, ".");
        index -= 4;
    }
    return array.join("");
}

// Đối tượng cần truyển dữ liệu để xử lý
(function($) {
    "use strict";
    $.extend($.fn, {
        validate: function( options ) {
            this.attr( "enctype", "multipart/form-data" ); // Upload file
            this.attr( "novalidate", "novalidate" ); // Add novalidate tag if HTML5.
            new $.validator( options, this[0] );
            //Tạo template thông báo khi submit
            if ($.validator.settings.setTempalte.modal) {
                $.validator.prototype.setTempalte($.validator.settings.setTempalte);
            }
            $(this).on( "submit change", function( event ) {
                new $.validator( options, this );
                var elems = Object.keys($.validator.elements);
                for (var i = 0; i < elems.length; i++) {
                    var el = $.validator.prototype.findByName(elems[i]);
                    if ((event.type === 'change' && elems[i] != event.target.name) || el[0] === undefined) {
                        continue;
                    }
                    var methods = Object.keys($.validator.elements[elems[i]]);
                    methods = Object.removeByValue(methods, 'notify');
                    for (var j = 0; j < methods.length; j++) {
                        var error = ($.validator.methods[methods[j]](el[0].value, el[0]) === false) ? methods[j] : '';
                        $.validator.prototype.showErrors(elems[i], error);
                        if (error !== '') {
                            $.validator.errorList.push(elems[i]);
                            $.validator.cancelSubmit = true;
                            break;
                        } 
                    }
                }
            });
            $(this).on( "submit", function( event ) {
                if ($.validator.cancelSubmit || $.validator.settings.ajax.url) {
                    event.preventDefault();
                    $.validator.prototype.focusInvalid();
                }
                if ($.validator.settings.ajax.url && !$.validator.cancelSubmit) {
                    $.ajax({
                        url: $.validator.settings.ajax.url,
                        type: $.validator.settings.ajax.type,
                        data: $.validator.prototype.getDataDefault(this),
                        beforeSend: function() {
                            $('.ht-loading-gif').remove();
                            $("body").append($.validator.settings.ajax.beforeSend);
                        }
                    })
                    // .done(function(data) {
                    //     var result = $.parseJSON(data);
                    //     if(result) {
                    //         $('#' + $.validator.settings.setTempalte.modal).find('.content-notify').text(result.notify);
                    //         $('#' + $.validator.settings.setTempalte.modal).find('[ht-reload]').attr('ht-reload', result.reload);
                    //     }
                    // })
                    .always(function() {
                        $('.ht-loading-gif').remove();
                        $('[ht-target=#'+$.validator.settings.setTempalte.modal+']').click();
                    });
                } 
                if (!$.validator.settings.ajax.url && !$.validator.cancelSubmit) { 
                    $('.ht-loading-gif').remove();
                    $("body").append($.validator.settings.ajax.beforeSend);
                }
            });
        },
    });

    $.validator = function( options, form ) {
        $.validator.settings = $.extend( true, {}, $.validator.defaults, options );
        $.validator.elements = $.validator.settings.rules;
        $.validator.cancelSubmit = false;
        $.validator.errorList = [];
        $.validator.currentForm = form;
    };

    $.extend( $.validator, {
        defaults: {
            rules: {},
            focusInvalid: true,
            ajax: {
                url: '',
                beforeSend: '<div class="ht-loading-gif"><img src="images/loading.gif" /></div>',
                type: 'post', // post/get
            },
            notify: {
                type: 'text', // text/icon
                position: 'left' // left/right/top/bottom
            },
            setTempalte: {
                // notify: 'Bạn đã gửi thành công.',
                // modal: 'myModal',
                reload: true,
                redirect: false,
            }
        },
        prototype: {
            showErrors: function( name, error ) {
                var element = this.findByName(name);
                $(element).closest('.form-group').removeClass('is-error');
                $(element).closest('.form-group').find(".form__error").remove();
                if (error) {
                    $(element).closest('.form-group').addClass('is-error');
                    var notifyText  = (typeof $.validator.elements[name][error] === 'string') ? $.validator.elements[name][error] : $.validator.elements[name][error].messages,
                        notifyType = $.validator.settings.notify.type,
                        notifyPosition = $.validator.settings.notify.position;
                    if ($.validator.elements[name].notify !== undefined) {
                        notifyType = $.validator.elements[name].notify.type;
                        notifyPosition = ($.validator.elements[name].notify.position) ? $.validator.elements[name].notify.position : $.validator.settings.notify.position;
                    }
                    switch(notifyType) {
                        case 'text':
                            $(element).closest('.form-group').append('<span class="form__error"><i class="fa fa-times-circle-o" aria-hidden="true"></i><span class="error-text">'+notifyText+'</span></span>');
                            break;
                        case 'icon':
                            $(element).closest('.form-group').append('<span class="form__error" ht-trigger="c-tooltip" ht-placement="' + notifyPosition + '" ht-content="' + notifyText + '" style="width: auto; z-index: 2;"><i class="fa fa-times-circle-o" aria-hidden="true"></i></span>');
                            break;
                    }
                }
            },
            focusInvalid: function() {
                if ($.validator.settings.focusInvalid) {
                    var el = $.validator.prototype.findByName($.validator.errorList[0]);
                    $(el[0]).filter( ":visible" ).focus().trigger( "focusin" );
                }
            },
            checkable: function( element ) {
                return ( /radio|checkbox/i ).test( element.type );
            },
            findByName: function( name ) {
                return $($.validator.currentForm).find( "[name='" + name + "']" );
            },
            getLength: function( value, element ) {
                switch ( element.nodeName.toLowerCase() ) {
                case "select":
                    return $( "option:selected", element ).length;
                case "input":
                    if ( this.checkable( element ) ) {
                        return this.findByName( element.name ).filter( ":checked" ).length;
                    }
                }
                return value.length;
            },
            elementValue: function( element ) {
                var val,
                    $element = $( element ),
                    type = element.type;

                if ( type === "radio" ) {
                    return this.findByName( element.name ).filter(":checked").val();
                } else if ( type === "number" && typeof element.validity !== "undefined" ) {
                    return element.validity.badInput ? false : $element.val();
                }

                val = $element.val();
                if ( typeof val === "string" ) {
                    return val.replace(/\r/g, "" );
                }
                return val;
            },
            getDataDefault: function( form ) {
                var elems = form.querySelectorAll('input, textarea');
                var obj = {};
                for (var i = 0; i < elems.length; i++) {
                    if (elems[i].type === 'checkbox') {
                        var chk_arr =  document.getElementsByName(elems[i].getAttribute('name'));
                        var chklength = chk_arr.length;      
                        var arrayField = [];       
                        for(var k = 0 ; k < chk_arr.length; k++)
                        {
                            if (chk_arr[k].checked === true) {
                                arrayField.push(chk_arr[k].value);
                            }
                        }
                        obj[elems[i].getAttribute('name')] = arrayField;
                    } else {
                        obj[elems[i].getAttribute('name')] = this.elementValue(elems[i]);
                    }
                }
                return obj;
            },
            setTempalte: function( obj ) {
                var html = '';
                html += '<a href="javascript:void(0);" ht-trigger="c-modal" ht-target="#'+obj.modal+'" style="display: none;"></a>'+
                        '<div id="'+obj.modal+'" class="modal modal-login">'+
                            '<div class="modal-box">'+
                                '<div class="modal-group" ht-skip="parent">'+
                                    '<div class="modal__body text-center">'+
                                        '<h4 class="content-notify">'+obj.notify+'</h4>'+
                                    '</div>'+
                                    '<div class="modal__footer text-center">'+
                                        '<button class="c-btn--sm c-btn--primary" ht-close="c-modal" ht-target-close="#'+obj.modal+'" ht-reload="'+obj.reload+'" ht-redirect="'+obj.redirect+'">OK</button>'+
                                    '</div>'+
                                '</div>'+
                            '</div>'+
                        '</div>';
                $('body').append(html);
            }
        },
        methods: {
            required: function( value, element ) {
                if ( element.nodeName.toLowerCase() === "select" ) {
                    // could be an array for select-multiple or a string, both are fine this way
                    var val = $( element ).val();
                    return val && val.length > 0;
                }
                if ( $.validator.prototype.checkable( element ) ) {
                    return $.validator.prototype.getLength( value, element ) > 0;
                }
                return value.length > 0;
            },
            email: function( value, element ) {
                return (value) ? /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm.test( value ) : true;
            },
            number: function( value, element ) {
                return (value) ? /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test( value ) : true;
            },
            maxlength: function( value, element) {
                var max = $.validator.elements[element.name].maxlength.max;
                var length = value.length;
                return length <= max;
            },
            minlength: function( value, element) {
                var min = $.validator.elements[element.name].minlength.min;
                var length = value.length;
                return length >= min;
            },
            confirm: function( value, element) {
                var a = $.validator.prototype.elementValue( $.validator.prototype.findByName($.validator.elements[element.name].confirm.elem)[0] );
                return (value == a) ? true : false;
            },
            ajax: function( value, element) {
                var result = {}; 
                result[element.name] = value;
                result.checkAjax = true;
                $.ajax({
                    url: $.validator.elements[element.name].ajax.url,
                    type: 'post',
                    data: result,
                    async: false
                })
                .done(function(data) {
                    result.error = $.parseJSON(data);
                });
                return (result.error) ? false : true;
            }
        }
    });
})(jQuery);