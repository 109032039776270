$(document).ready(function() {
    $('.s-header-2b a[ht-trigger=hd-menu]').click(function (){
        $('.s-header-2b').find('a[ht-trigger=hd-menu], .hd-menu__list').toggleClass("is-active");
    });
    if (window.innerWidth < 1200) {
        $('.s-header-2b .has_sub-menu').find('ul').css('width', '100%');
        $('.s-header-2b .has_sub-menu').removeAttr('style');
        $('.s-header-2b .has_sub-menu > a').click(function (e){
            e.preventDefault();
            $(this).parent().toggleClass("is-focus");
        });
    } else {
        var el_parent = $('.s-header-2b .has_sub-menu'),
            maxWidth = 400, Width = 0;
        for (var i = 0; i < el_parent.length; i++) {
            Width = $(el_parent[i]).find('ul').outerWidth() + 15;
            Width = (Width > maxWidth) ? maxWidth : Width;
            $(el_parent[i]).css('position', 'relative');
            $(el_parent[i]).find('ul').css('width', Width);
        }
    }
    $(window).on("resize",function() {
        if (window.innerWidth < 1200) {
            $('.s-header-2b .has_sub-menu').find('ul').css('width', 'auto');
            $('.s-header-2b .has_sub-menu').removeAttr('style');
            $('.s-header-2b .has_sub-menu > a').click(function (e){
                e.preventDefault();
                $(this).parent().toggleClass("is-focus");
            });
        } else {
            var el_parent = $('.s-header-2b .has_sub-menu'),
                maxWidth = 400, Width = 0;
            for (var i = 0; i < el_parent.length; i++) {
                Width = $(el_parent[i]).find('ul').outerWidth() + 15;
                Width = (Width > maxWidth) ? maxWidth : Width;
                $(el_parent[i]).css('position', 'relative');
                $(el_parent[i]).find('ul').css('width', Width);
            }
        }
    });
});